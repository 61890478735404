import React, { Fragment } from 'react'
import BeforeAfterGroup from './beforeAfterGroup'
import CallOutHolder from './callOutHolder'
import SingleCallOut from './singleCallOut'
import FAQ from '../faq'
import { Helmet } from "react-helmet"


const pageBuilder = props => {
    let builds = '';
    let faqSchema = {}
    //console.log(props)
    if (props.data !== null && props.data[0].__typename === 'ContentfulFaq') {
      const FAQ = props.data.map(faq => {
        if(faq.__typename === 'ContentfulFaq') {
          let answer = ''; 
          faq.answer.json.content[0].content.map(a => {
            if(a.nodeType === 'hyperlink') {

            }
            if(a.nodeType === 'text') {
              answer = a.value
            }
          })
          return {
            "@type": "Question",
            name: faq.question.question,
            acceptedAnswer: {
              "@type": "Answer",
              text: answer,
            },
          }
        }
      })
      faqSchema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [FAQ],
      }
      console.log('schema', faqSchema);
    }

    if(props.data) {
      builds = props.data.map((data, key) => {
        switch (data.__typename) {
          case 'ContentfulSingleCallout':
            return (<SingleCallOut data={data} key={data.__typename + '_' + key} />);
          case 'ContentfulBeforeAfterGroup':
            return (<BeforeAfterGroup data={data} key={data.__typename + '_' + key}/>);
          case 'ContentfulCalloutHolder':
            return (<CallOutHolder data={data} key={data.__typename + '_' + key} />);
          case 'ContentfulFaq':
            return (<Fragment key={data.__typename + '_' + key}><FAQ data={data} /><Helmet> {Object.keys(faqSchema).length && (<script type="application/ld+json">{JSON.stringify(faqSchema)}</script>)}</Helmet></Fragment>);
          default:
            return null;
        }
      });
    }
    return <Fragment>{ builds && builds }</Fragment>
}

export default pageBuilder