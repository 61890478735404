import React from 'react'
import Testimonials from '../testimonials'
import CaseStudies from '../caseStudies'
import Statistics from '../statistics'
import ButtonFields from '../buttonFields'
import RenderRichText from '../renderRichText'

const singleCallOut = ({data}) => {
	return (
		<div className={`single-callout ${data.backgroundColor === 'Dark' ? 'dark' : data.backgroundColor === 'Red' ? 'red' : ''} ${data.extraClass ? data.extraClass : ''}`}>
			<div className="row">
				<div className="col-md-12">
					{ data.title && <h2 className="text-center">{ data.title }</h2> }

					{ data.calloutText &&  
						<div className="single-callout-richtext">
							<RenderRichText data={data.calloutText.json} imgLink={data.imageLink} />
						</div>
					}
					{ data.testimonials && data.testimonials.length > 1 && 
						<div className="card-group testimonials">
							<Testimonials data={data.testimonials} />
						</div>
					} 
					{ data.caseStudies && 
						<div className="article-list case-studies">
							<div className="container">
								<div className="row articles">
									<CaseStudies data={data.caseStudies} /> 
								</div>
							</div>
						</div>
					}
					{ data.statistics && 
						<div className="features-boxed statistics">
								<div className="container">
										<div className="row justify-content-center features">
												<Statistics data={data.statistics} />
										</div>
								</div>
						</div> 
					}
					{ data.button && 
						<div className="text-center">
								<ButtonFields data={data.button} />
						</div>
					} 
				</div>
			</div>
	</div>
	)
}

export default singleCallOut