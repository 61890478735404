import React from 'react'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Faq = ({data}) => {
  return (
    <div className="container faq-item">
      <h3 className="faq-question"> { data.question.question }</h3>
      <div className="faq-answer">{ documentToReactComponents(data.answer.json) }</div>
    </div>
  )
}

export default Faq