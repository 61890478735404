import React, { Component } from 'react'
import { Carousel } from 'react-bootstrap'
import Slides from '../slides'
import RenderRichText from '../renderRichText'
//import BeforeAfter from '../beforeAfter'

class BeforeAfterGroups extends Component {

  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  next() {
    let { resultsGroup } = this.props.data;
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === resultsGroup.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    let { resultsGroup } = this.props.data;
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? resultsGroup.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  handleSelect(selectedIndex) {
    this.goToIndex(selectedIndex);
  };

  render() {
    const { activeIndex } = this.state;
    return (
      <div className="beforeAfterCarouselHolder">
      <Carousel 
        className="before-after-carousel" 
        touch={true} 
        indicators={false} 
        activeIndex={activeIndex} 
        interval={5000} 
        onSelect={(e) => this.handleSelect(e)}
      >
        { this.props.data.resultsGroup.map((item, count) => 
          <Carousel.Item key={count}>
            <div className="container before-after-holder">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2 className="text-center">Experience the Difference</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 images-holder">
                        <p className="before-text">Before</p>
                        <p className="after-text">After</p>
                        <img src={item.beforeImage.fluid.src} width="50%" alt={ item.beforeImage.fluid.title }/>
                        <img src={item.afterImage.fluid.src} width="50%" alt={ item.beforeImage.fluid.title }/>
                    </div>
                    <div className="col-lg-6 context-holder">
                        <div className="card before-after-content">
                            <div className="card-body before-after-rich-text">
                                <RenderRichText data={item.resultsDetails.json} />
                            </div>
                        </div>
                        { item.testimonial && <Slides data={item.testimonial} /> }
                    </div>
                </div>
            </div>
          </Carousel.Item>
        )}
      </Carousel>
      <div className="pagination row">
      <ul className="before-after-pagination">
        <li onClick={() => this.previous()} className="ba-prev">prev</li>
        {this.props.data.resultsGroup.map((item, index) => {
          return (
            <li
              key={index}
              onClick={() => this.goToIndex(index)}
              className={`ba-indicator ${activeIndex === index ? 'active' : ''}`}>Slide {index}
            </li>
          );
        })}
        <li onClick={() => this.next()}  className="ba-next">next</li>
      </ul>
      </div>
      </div>
    )
  }
}

export default BeforeAfterGroups