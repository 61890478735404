import React, { Fragment } from 'react'
import { Card } from 'react-bootstrap'

const Slides = ({data}) => {
  return (
    <Fragment>
      <Card className="testimonial">
        <Card.Title className="card-title">{ data?.title }</Card.Title>
        <Card.Body>
          <Card.Text>{ data.quote?.quote }</Card.Text>
          <Card.Link href="#">{ data?.name }</Card.Link>
          <Card.Link href="#">{ data?.occupation }</Card.Link>
          <Card.Link href="#">{ data?.location }</Card.Link>
        </Card.Body>
      </Card>
    </Fragment>
  ) 
}

export default Slides